export interface ICancellationPolicy {
  hours: number;
  unitType: string;
  value: number;
  verbiage: string;
}

export interface IBusiness {
  id: string;
  name: string;
  friendlyId: string;
  cancellationPolicy: ICancellationPolicy;
}

export const BLANK_CANCELLATION_POLICY: ICancellationPolicy = {
  hours: 0,
  unitType: '',
  value: 0,
  verbiage: '',
};
