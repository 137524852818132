export interface IAvailabilityRate {
  singleRate: number;
  couplesRate: number;
}

export interface IAvailabilityTreatmentRate {
  id: string;
  rate: number;
}

export interface IAvailabilityRoom {
  roomId: string;
  name: string;
  capacity: number;
}

export interface IAvailabilityTreatment {
  id: string;
  name: string;
  type: string;
  duration: number;
  recipientIndex: number;
  category: string;
}

export interface IAvailabilityTherapist {
  accountId: string;
  staffId: string;
  firstName: string;
  lastName: string;
  gender: string;
  treatments: IAvailabilityTreatment[];
  recipientIndex: number;
}

export interface IAvailabilityTimeSlot {
  start: string;
  duration: number;
}

export interface IAvailableSlot {
  id: string;
  category: string;
  baseRate: number;
  treatmentRates: IAvailabilityTreatmentRate[];
  isSoldOut: boolean;
  selectedRoom: IAvailabilityRoom;
  rooms: IAvailabilityRoom[];
  therapists: IAvailabilityTherapist[];
  treatments: IAvailabilityTreatment[];
  timeSlot: IAvailabilityTimeSlot;
  groupSize: number;
}

export const BLANK_AVAILABILITY_ROOM: IAvailabilityRoom = {
  roomId: '',
  name: '',
  capacity: 0,
};

export const BLANK_AVAILABILITY: IAvailableSlot = {
  id: '',
  category: '',
  baseRate: 0,
  treatmentRates: [],
  isSoldOut: true,
  selectedRoom: BLANK_AVAILABILITY_ROOM,
  rooms: [],
  therapists: [],
  treatments: [],
  timeSlot: {
    start: '',
    duration: 0,
  },
  groupSize: 0,
};

export const BLANK_AVAILABILITY_TREATMENT: IAvailabilityTreatment = {
  id: '',
  name: '',
  type: '',
  duration: 0,
  recipientIndex: 1,
  category: '',
};

export const BLANK_AVAILABILITY_THERAPIST: IAvailabilityTherapist = {
  accountId: '',
  staffId: '',
  firstName: '',
  lastName: '',
  gender: '',
  treatments: [],
  recipientIndex: 1,
};
