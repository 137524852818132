export enum TransactionStatus {
  Initial = 'Initial',
  Captured = 'Captured',
  Refunded = 'Refunded',
  PartiallyRefunded = 'PartiallyRefunded',
  Canceled = 'Canceled',
  Failed = 'Failed',
  Pending = 'Pending',
}

export enum TransactionType {
  Payment = 'Payment',
  Refund = 'Refund',
  Tip = 'Tip',
}

export enum LineItemType {
  Treatment = 'Treatment',
  AddOn = 'AddOn',
  CancellationPolicyAdjustment = 'CancellationPolicyAdjustment',
  Product = 'Product',
  Discount = 'Discount',
  Other = 'Other',
}

export enum PaymentMethod {
  NewCard = 'NewCard',
  CardOnFile = 'CardOnFile',
  GiftCard = 'GiftCard',
  Voucher = 'Voucher',
}

export interface IOrderTransaction {
  id: string;
  amount: number;
  status: TransactionStatus;
  failReason: string;
  gateway: string; // enum
  paymentMethod: string; // enum
  transactionType: TransactionType;
  lineItemId: string;
  capturedUtc: Date | null;
  failedUtc: Date | null;
  tipAmount: number;
  refundPaymentId: string;
  memo: string | null;
  paymentSourceId: string | null;
  marketplaceCharge: number;
}

export interface IOrderLineItem {
  id: string;
  name: string;
  price: number;
  created?: Date;
  description?: string;
  duration?: number;
  scheduledFor?: Date;
  type?: LineItemType;
  updated?: Date | null;
  voided?: boolean;
}
export interface IOrder {
  id: string;
  bookingId: string;
  customerId: string;
  locationId: string;
  timeZone: string;
  state: string; // enum
  total: number;
  totalPaid: number;
  balance: number;
  failureReason: string;
  transactions: IOrderTransaction[];
  lineItems: IOrderLineItem[];
  createdUtc: Date | null;
  tipAmountPaid: number;
}
export interface ICheckoutDevice {
  id: string;
  squareDeviceId: string;
  squareDeviceCode: string;
  deviceName: string;
}

export const BLANK_ORDER: IOrder = {
  id: '',
  bookingId: '',
  customerId: '',
  locationId: '',
  timeZone: '',
  state: '',
  total: 0,
  totalPaid: 0,
  balance: 0,
  failureReason: '',
  transactions: [],
  lineItems: [],
  createdUtc: null,
  tipAmountPaid: 0,
};
