import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosClient } from '@src/app/AxiosClient';
import { ILocation } from './types';

export enum RequestStatus {
  Initial,
  Pending,
  Success,
  PartialSuccess,
  Fail,
}

interface LocationsState {
  locations: ILocation[];
  getLocationsStatus: RequestStatus;
}

const initialState: LocationsState = {
  locations: [],
  getLocationsStatus: RequestStatus.Initial,
};

export const getAllLocations = createAsyncThunk<
  LocationsState,
  string[],
  { state: LocationsState }
>('locations/getAllLocations', async (businessIds: string[]) => {
  let getLocationsStatus = RequestStatus.Pending;
  const api = new AxiosClient({});
  const locations = [];
  for (let index = 0; index < businessIds.length; index += 1) {
    try {
      const businessId = businessIds.at(index);
      if (businessId) {
        // eslint-disable-next-line no-await-in-loop
        const location = await api.getRequest(
          `/locations/business/${businessId}`
        );
        locations.push(...location.data);
      }
    } catch {
      getLocationsStatus = RequestStatus.PartialSuccess;
    }
  }

  if (getLocationsStatus !== RequestStatus.PartialSuccess)
    getLocationsStatus = RequestStatus.Success;

  return {
    locations,
    getLocationsStatus,
  };
});

export const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllLocations.pending, (state) => {
        state.getLocationsStatus = RequestStatus.Pending;
      })
      .addCase(getAllLocations.fulfilled, (state, action) => {
        state.getLocationsStatus = action.payload.getLocationsStatus;
        state.locations = action.payload.locations;
      })
      .addCase(getAllLocations.rejected, (state) => {
        state.getLocationsStatus = RequestStatus.Fail;
      });
  },
});

export default locationsSlice.reducer;
