import appConfig from '@src/configs/appConfig';
import { AxiosClient, AxiosClientAttributes } from '../../app/AxiosClient';
import { ITokenResponse } from './types';

export default class IdentityServerApi extends AxiosClient {
  constructor(attributes: AxiosClientAttributes) {
    super({ ...attributes });
  }

  async getToken(params: URLSearchParams): Promise<ITokenResponse> {
    const response = await this.postRequest(
      appConfig.urls.IDENTITY_SERVER_TOKEN_ENDPOINT,
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    return response.data;
  }
}
