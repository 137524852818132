import { Role } from '@src/app/types';

export interface ITokenResponse {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  id_token: string;
}

export interface IDecodedIdToken {
  sub: string;
  loc: string | string[];
  role: string | string[];
  exp: number;
}

export interface IAbility {
  action: string;
  subject: string;
}

export interface IUserData {
  accountId: string;
  roles: Role[];
  locationClaims: string[];
  abilities: IAbility[];
}

export const BLANK_USER_DATA: IUserData = {
  accountId: '',
  roles: [],
  locationClaims: [],
  abilities: [],
};
