
const configuration = {
  welcomeMessage: "Hello from PROD",
  urls: {
    baseUrl: "https://api.spaccurate.com",
    IDENTITY_SERVER: "https://iam.spaccurate.com",
    IDENTITY_SERVER_AUTHORIZE_ENDPOINT: "/connect/authorize",
    IDENTITY_SERVER_TOKEN_ENDPOINT: "/connect/token",
    IDENTITY_SERVER_LOGOUT_ENDPOINT: "/connect/endsession",
    SQUARE_APPLICATION_ID: "sq0idp-0DI4vtLrQCH4hsZiDuwgyQ",
    SQUARE_LOCATION_ID: "LVM9V6XNTAPQV",
    ADMIN_WEB_APP: "https://business.spaccurate.com",
    CORPORATE_WEB_APP: "https://corporate.spaccurate.com"
  },
  INSTRUMENTATION_KEY: "98d7bded-8bea-4eaa-8ac6-5eaf322e2c29",
  BUSINESS_ID: "b9e7912f-1928-4aa1-9366-1b26499df91b",
  STRIPE_PUBLISHABLE_KEY: "pk_live_51OWmWWAbGz2rAwn2yoTPeAYjDkqYgxPAlUqDVZrvNgkDj4bbVSV3bHWYtg0eYQADT38AyL8OBzciYC0As58zKAtO003rSxufwd"
}

export default configuration
