/* eslint-disable no-await-in-loop */
import { createSlice } from '@reduxjs/toolkit';
import { PaymentMethod } from './types';
import { IGiftCard } from '../api/CreditLedgerApi';

export interface PaymentState {
  isCancellationPolicyAccepted: boolean;
  selectedTipAmount: string;
  selectedPaymentAmount: string;
  selectedPaymentMethod: PaymentMethod | null;
  selectedPaymentMethodId: string;
  selectedCardId: string | null;
  selectedPaymentToken: string | null;
  saveCard: boolean;
  selectedMemo: string;
  selectedGiftCard: IGiftCard | null;
  giftCardAppliedCode: string;
  clientSecret: string | null;
  transactionId: string | null;
  marketplaceFeeCharge: number | null;
}

const initialState: PaymentState = {
  isCancellationPolicyAccepted: false,
  selectedTipAmount: '',
  selectedPaymentAmount: '',
  selectedPaymentMethod: null,
  selectedPaymentMethodId: '',
  selectedCardId: null,
  selectedPaymentToken: null,
  saveCard: true,
  selectedMemo: '',
  selectedGiftCard: null,
  giftCardAppliedCode: '',
  clientSecret: null,
  transactionId: null,
  marketplaceFeeCharge: null,
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    toggleCancellationPolicy: (state: PaymentState) => {
      state.isCancellationPolicyAccepted = !state.isCancellationPolicyAccepted;
    },
    selectTipAmount: (state: PaymentState, action: { payload: string }) => {
      state.selectedTipAmount = action.payload;
    },
    selectPaymentAmount: (state: PaymentState, action: { payload: string }) => {
      state.selectedPaymentAmount = action.payload;
    },
    resetCustomerPaymentState: (state: PaymentState) => {
      state.selectedTipAmount = '';
      state.selectedPaymentAmount = '';
      state.selectedPaymentMethod = null;
      state.selectedPaymentMethodId = '';
      state.selectedCardId = null;
      state.selectedPaymentToken = null;
      state.selectedMemo = '';
      state.isCancellationPolicyAccepted = false;
      state.selectedGiftCard = null;
      state.giftCardAppliedCode = '';
      state.transactionId = null;
      state.clientSecret = null;
      state.marketplaceFeeCharge = null;
    },
    selectPaymentMethod: (
      state: PaymentState,
      action: {
        payload: {
          paymentMethod: PaymentMethod | null;
        };
        type: string;
      }
    ) => {
      state.selectedPaymentMethod = action.payload.paymentMethod;
    },
    selectPaymentMethodId: (
      state: PaymentState,
      action: {
        payload: {
          paymentMethodId: string;
        };
        type: string;
      }
    ) => {
      state.selectedPaymentMethodId = action.payload.paymentMethodId;
    },
    selectCardId: (
      state: PaymentState,
      action: {
        payload: {
          cardId: string | null;
        };
        type: string;
      }
    ) => {
      state.selectedCardId = action.payload.cardId;
    },
    selectPaymentToken: (
      state: PaymentState,
      action: {
        payload: {
          paymentToken: string | null;
        };
        type: string;
      }
    ) => {
      state.selectedPaymentToken = action.payload.paymentToken;
    },
    selectGiftCard: (
      state: PaymentState,
      action: {
        payload: {
          giftCard: IGiftCard | null;
        };
        type: string;
      }
    ) => {
      state.selectedGiftCard = action.payload.giftCard;
    },
    setGiftCardAppliedCode: (
      state: PaymentState,
      action: {
        payload: {
          giftCardCode: string;
        };
        type: string;
      }
    ) => {
      state.giftCardAppliedCode = action.payload.giftCardCode;
    },
    setSaveCard: (state: PaymentState) => {
      state.saveCard = !state.saveCard;
    },
    setMemo: (
      state: PaymentState,
      action: {
        payload: string | null;
        type: string;
      }
    ) => {
      state.selectedMemo = action.payload ?? '';
    },
    setClientSecret: (
      state: PaymentState,
      action: { payload: string | null }
    ) => {
      state.clientSecret = action.payload;
    },
    setTransactionId: (
      state: PaymentState,
      action: { payload: string | null }
    ) => {
      state.transactionId = action.payload;
    },
    setMarketplaceCharge: (
      state: PaymentState,
      action: { payload: number | null }
    ) => {
      state.marketplaceFeeCharge = action.payload;
    },
    resetPaymentStateAfterOrderUpdateError: (state: PaymentState) => {
      if (state.selectedPaymentMethod !== PaymentMethod.NewCard) {
        state.selectedPaymentMethod = null;
        state.selectedPaymentMethodId = '';
      }
      state.giftCardAppliedCode = '';
      state.selectedCardId = null;
      state.selectedGiftCard = null;
    },
  },
});

export const {
  toggleCancellationPolicy,
  selectTipAmount,
  selectPaymentAmount,
  resetCustomerPaymentState,
  selectPaymentMethod,
  selectPaymentMethodId,
  setSaveCard,
  selectCardId,
  selectPaymentToken,
  setMemo,
  selectGiftCard,
  setGiftCardAppliedCode,
  setClientSecret,
  setTransactionId,
  setMarketplaceCharge,
  resetPaymentStateAfterOrderUpdateError,
} = paymentSlice.actions;

export default paymentSlice.reducer;
