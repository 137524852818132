// ** Redux Imports
import { configureStore } from '@reduxjs/toolkit';
import { api, apiExample, apiNoAuth } from '@src/features/api';
import rootReducer from './rootReducer';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(api.middleware)
      .concat(apiNoAuth.middleware)
      .concat(apiExample.middleware),
});

export { store };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
