import { TimeZone } from '@src/app/types';
import { BLANK_CANCELLATION_POLICY, IBusiness } from '../business/types';

export interface IWorkDay {
  disabled: boolean;
  dayOfWeek: string;
  openingTime?: string | null;
  closingTime?: string | null;
}

export interface ILocationHours {
  timeZone: TimeZone;
  hoursOfOperations: IWorkDay[];
}

interface Address {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
}
export interface ILocation {
  id: string;
  businessId: string;
  name: string;
  phoneNumber: string;
  email: string;
  address: string;
  // hoursOfOperations?: IWorkDay[];
  timeZone: TimeZone;
  friendlyId: string;
  isActive: boolean;
  fullAddress: Address;
}

export interface ILocationRatesForecast {
  locationId: string;
  forecasts: IRateForecast[];
  overallLowestRate: number;
}

export interface IRateForecast {
  date: Date;
  dayOfWeek: string;
  lowestRate: number;
  averageRate: number;
  highestRate: number;
}

export interface IOperationException {
  date: string;
}

export const BLANK_LOCATION: ILocation = {
  id: '',
  businessId: '',
  name: '',
  address: '',
  phoneNumber: '',
  email: '',
  timeZone: TimeZone['America/New_York'],
  friendlyId: '',
  isActive: false,
  fullAddress: {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
  },
};

export const BLANK_LOCATION_HOURS: ILocationHours = {
  hoursOfOperations: [],
  timeZone: TimeZone['America/New_York'],
};

export const BLANK_BUSINESS: IBusiness = {
  id: '',
  friendlyId: '',
  name: '',
  cancellationPolicy: BLANK_CANCELLATION_POLICY,
};
