import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import appConfig from '@src/configs/appConfig';

let appInsights: ApplicationInsights | null = null;

/**
 * Create the App Insights Telemetry Service
 * @return {{appInsights: ApplicationInsights | null, initialize: Function}} - Object
 */
const createTelemetryService = (): {
  appInsights: ApplicationInsights | null;
  initialize: () => void;
} => {
  /**
   * Initialize the Application Insights class
   * @return {void}
   */
  const initialize = (): void => {
    // if (!appConfig.instrumentationKey) {
    //   throw new Error('Instrumentation key not provided');
    // }

    appInsights = new ApplicationInsights({
      config: {
        // TODO: pass our instrumentation key in pipeline
        instrumentationKey: appConfig.INSTRUMENTATION_KEY,
        enableAutoRouteTracking: true,
        loggingLevelTelemetry: 2,
        // enableDebug: true,
      },
    });

    appInsights.loadAppInsights();
    appInsights.trackPageView();

    // const filteringFunction = (envelope: ITelemetryItem) => {
    //   if (envelope.data) {
    //     // const state = Flex.Manager.getInstance().store.getState();
    //     const correlationId =
    //       state.proproctor?.sharedState?.activeExamSession?.correlationId;
    //     if (correlationId) {
    //       envelope.data.CorrelationId = correlationId;
    //     }
    //   }

    //   return true;
    // };
    // appInsights.addTelemetryInitializer(filteringFunction);
  };

  return { appInsights, initialize };
};

export const ai = createTelemetryService();
export const getAppInsights = (): ApplicationInsights | null => appInsights;
