export enum TimeZone {
  // Windows - remove after transitioning to IANA
  'Hawaiian Standard Time' = 'Hawaiian Standard Time',
  'Alaskan Standard Time' = 'Alaskan Standard Time',
  'Pacific Standard Time' = 'Pacific Standard Time',
  'Mountain Standard Time' = 'Mountain Standard Time',
  'US Mountain Standard Time' = 'US Mountain Standard Time',
  'Central Standard Time' = 'Central Standard Time',
  'Eastern Standard Time' = 'Eastern Standard Time',
  // IANA
  'America/Anchorage' = 'America/Anchorage',
  'America/Chicago' = 'America/Chicago',
  'America/Denver' = 'America/Denver',
  'America/Los_Angeles' = 'America/Los_Angeles',
  'America/New_York' = 'America/New_York',
  'America/Phoenix' = 'America/Phoenix',
  'Pacific/Honolulu' = 'Pacific/Honolulu',
}

export const Weekday = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export enum Role {
  Owner = 'owner',
  Manager = 'manager',
  Therapist = 'therapist',
  Receptionist = 'receptionist',
  Customer = 'customer',
}

export type RoleType = {
  name: Role;
};

export type OptionType = {
  value: string;
  label: string;
};

export interface IOption {
  value: string | number;
  label: string;
}

export const roleOptions: IOption[] = [
  { value: Role.Therapist, label: 'Therapist' },
  { value: Role.Receptionist, label: 'Receptionist' },
  { value: Role.Manager, label: 'Manager' },
];

export type RequestError = {
  status: number;
  data?: {
    reason?: string;
  };
};

export interface IAccount {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  roles: Role[];
  businessId: string;
}

export const BLANK_ACCOUNT: IAccount = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  roles: [],
  businessId: '',
};
