// ** Reducers Imports
import { api, apiExample, apiNoAuth } from '@src/features/api';
import auth from '../features/authentication/AuthenticationSlice';
import navbar from '../features/navbar/navbar';
import layout from '../features/layout/layout';
import booking from '../features/booking/BookingSlice';
import payment from '../features/payment/PaymentSlice';
import locations from '../features/locations/LocationsSlice';
import timeSlotFiltering from '../features/pricing/components/TimeSlotFilteringSlice';

const rootReducer = {
  auth,
  navbar,
  layout,
  booking,
  payment,
  locations,
  timeSlotFiltering,
  [api.reducerPath]: api.reducer,
  [apiNoAuth.reducerPath]: apiNoAuth.reducer,
  [apiExample.reducerPath]: apiExample.reducer,
};

export default rootReducer;
