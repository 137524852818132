
const configuration = {
    welcomeMessage: "Hello from DEV",
    urls: {
        baseUrl: "https://dev-api.spaccurate.com",
        IDENTITY_SERVER: "https://dev-iam.spaccurate.com",
        IDENTITY_SERVER_AUTHORIZE_ENDPOINT: "/connect/authorize",
        IDENTITY_SERVER_TOKEN_ENDPOINT: "/connect/token",
        IDENTITY_SERVER_LOGOUT_ENDPOINT: "/connect/endsession",
        SQUARE_APPLICATION_ID: "sandbox-sq0idb-6kVBkaeRS4_e1GfzcRIj8g",
        SQUARE_LOCATION_ID: "LPJVFA1HS2EBC",
        ADMIN_WEB_APP: "https://dev-business.spaccurate.com",
        CORPORATE_WEB_APP: "https://corporate.spaccurate.com"
    },
    INSTRUMENTATION_KEY: "6551d226-1aad-4f8a-9f2d-fb297ea3e632",
    BUSINESS_ID: "83e5ce39-cd3c-46f0-8d9c-1b37e8709396",
    STRIPE_PUBLISHABLE_KEY: "pk_test_51OWmWWAbGz2rAwn21rPtucVzpC1tmD9pOHbNqcstiy2J7y6yz2lopSU88q5wzzIilhrBOqcKoOKZXIH8MkKUCpeR0076rB6dlT"
}

export default configuration
