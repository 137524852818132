// ** Initial user ability
export const initialAbility = [
    {
        action: 'read',
        subject: 'sign-in'
    },
    {
        action: 'read',
        subject: 'locations'
    }
]

export default initialAbility;