import { createSlice } from '@reduxjs/toolkit';
import deepEqual from 'fast-deep-equal/es6';

export const startingMinPriceFilter = 0;
export const startingMaxPriceFilter = 500;

export enum Gender {
  Male = 'Male',
  Female = 'Female',
}

export type ServiceTypeFilter = { displayName: string; typeId: string };

export type FilterOption<T> = { value: T; selected: boolean };

export interface TimeSlotFilteringState {
  selectedGroupSizes: FilterOption<number>[];
  selectedGenders: FilterOption<Gender>[];
  selectedDurations: FilterOption<number>[];
  selectedServiceTypes: FilterOption<ServiceTypeFilter>[];
  selectedMinPrice: number;
  selectedMaxPrice: number;
  availabilityCutOffDate: Date;
}

const initialState: TimeSlotFilteringState = {
  selectedGroupSizes: [
    { value: 1, selected: false },
    { value: 2, selected: false },
  ],
  selectedGenders: [
    { value: Gender.Male, selected: false },
    { value: Gender.Female, selected: false },
  ],
  selectedDurations: [],
  selectedServiceTypes: [],
  selectedMinPrice: startingMinPriceFilter,
  selectedMaxPrice: startingMaxPriceFilter,
  availabilityCutOffDate: new Date(new Date().getTime() + 3600 * 1000),
};

function updateObjectPropertyByValue<T>(
  arr: FilterOption<T>[],
  newValue: T,
  initial: boolean
): FilterOption<T>[] {
  const updatedArray: FilterOption<T>[] = [...arr];
  const index = updatedArray.findIndex((a) => deepEqual(a.value, newValue));
  if (index === -1) {
    updatedArray.push({
      value: newValue,
      selected: false,
    });
    return updatedArray;
  }

  if (!initial) {
    updatedArray[index].selected = !updatedArray[index].selected;
  }

  return updatedArray;
}

export type FilterOptionPayload<T> = {
  value: T;
  initial: boolean;
};

export const getFilterLocationContextString = (
  locationId: string,
  category: string | null
) => `${locationId}|${category ?? 'unset'}`;

const timeSlotFilteringSlice = createSlice({
  name: 'timeSlotFiltering',
  initialState,
  reducers: {
    filterGroupSize: (
      state: TimeSlotFilteringState,
      action: { payload: FilterOptionPayload<number>; type: string }
    ) => {
      state.selectedGroupSizes = updateObjectPropertyByValue<number>(
        state.selectedGroupSizes,
        action.payload.value,
        action.payload.initial
      ).sort((a, b) => a.value - b.value);
    },
    filterGender: (
      state: TimeSlotFilteringState,
      action: { payload: FilterOptionPayload<Gender>; type: string }
    ) => {
      state.selectedGenders = updateObjectPropertyByValue<Gender>(
        state.selectedGenders,
        action.payload.value,
        action.payload.initial
      );
    },
    filterDuration: (
      state: TimeSlotFilteringState,
      action: { payload: FilterOptionPayload<number>; type: string }
    ) => {
      const index = state.selectedDurations.findIndex(
        (duration) => duration.value === action.payload.value
      );
      if (index === -1) {
        state.selectedDurations.push({
          value: action.payload.value,
          selected: false,
        });
      } else if (!action.payload.initial) {
        state.selectedDurations[index].selected =
          !state.selectedDurations[index].selected;
      }

      state.selectedDurations.sort((a, b) => a.value - b.value);
    },
    filterServiceType: (
      state: TimeSlotFilteringState,
      action: {
        payload: FilterOptionPayload<ServiceTypeFilter>;
        type: string;
      }
    ) => {
      state.selectedServiceTypes =
        updateObjectPropertyByValue<ServiceTypeFilter>(
          state.selectedServiceTypes,
          action.payload.value,
          action.payload.initial
        ).sort((a, b) =>
          a.value.displayName.localeCompare(b.value.displayName)
        );
    },
    filterMinPrice: (
      state: TimeSlotFilteringState,
      action: { payload: number; type: string }
    ) => {
      state.selectedMinPrice = action.payload;
    },
    filterMaxPrice: (
      state: TimeSlotFilteringState,
      action: { payload: number; type: string }
    ) => {
      state.selectedMaxPrice = action.payload;
    },
    clearFilters: (state: TimeSlotFilteringState) => {
      state.selectedMinPrice = initialState.selectedMinPrice;
      state.selectedMaxPrice = initialState.selectedMaxPrice;
      state.selectedGroupSizes = state.selectedGroupSizes.map((obj) => {
        return { ...obj, selected: false };
      });
      state.selectedGenders = state.selectedGenders.map((obj) => {
        return { ...obj, selected: false };
      });
      state.selectedDurations = state.selectedDurations.map((obj) => {
        return { ...obj, selected: false };
      });
      state.selectedServiceTypes = state.selectedServiceTypes.map((obj) => {
        return { ...obj, selected: false };
      });
    },
    clearGroupSize: (state: TimeSlotFilteringState) => {
      state.selectedGroupSizes = state.selectedGroupSizes.map((obj) => {
        return { ...obj, selected: false };
      });
    },
    clearGender: (state: TimeSlotFilteringState) => {
      state.selectedGenders = state.selectedGenders.map((obj) => {
        return { ...obj, selected: false };
      });
    },
    clearDuration: (state: TimeSlotFilteringState) => {
      state.selectedDurations = state.selectedDurations.map((obj) => {
        return { ...obj, selected: false };
      });
    },
    clearServiceType: (state: TimeSlotFilteringState) => {
      state.selectedServiceTypes = state.selectedServiceTypes.map((obj) => {
        return { ...obj, selected: false };
      });
    },
    setAvailabilityCutOffDate: (state: TimeSlotFilteringState) => {
      state.availabilityCutOffDate = initialState.availabilityCutOffDate;
    },
  },
});

export const {
  filterGroupSize,
  filterDuration,
  filterGender,
  filterMinPrice,
  filterMaxPrice,
  clearFilters,
  filterServiceType,
  clearDuration,
  clearGender,
  clearGroupSize,
  clearServiceType,
  setAvailabilityCutOffDate,
} = timeSlotFilteringSlice.actions;

export default timeSlotFilteringSlice.reducer;
