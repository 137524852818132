import jwt from '@src/auth/jwt/useJwt';
import axios from 'axios';
import appConfig from '@src/configs/appConfig';
import {
  decodeExpiresAtFromToken,
  decodeUserDataFromIdToken,
} from '@src/auth/utils';
import { ITokenResponse, IUserData } from './types';

// Extracted functions to avoid circular dependencies
const config = jwt.jwtConfig;

export const clearStorage = () => {
  localStorage.removeItem(config.userDataKeyName);
  localStorage.removeItem(config.storageTokenResponseKeyName);
  localStorage.removeItem(config.storageTokenKeyName);
  localStorage.removeItem(config.storageRefreshTokenKeyName);
  localStorage.removeItem(config.storageTokenExpiresAtKeyName);
  localStorage.removeItem(config.storageIdTokenKeyName);
};

export function setLocalStorageValues(tokenResponse: ITokenResponse) {
  const expiresAt = decodeExpiresAtFromToken(tokenResponse.access_token);
  const userData: IUserData = decodeUserDataFromIdToken(tokenResponse.id_token);
  localStorage.setItem(config.userDataKeyName, JSON.stringify(userData));
  localStorage.setItem(
    config.storageTokenResponseKeyName,
    JSON.stringify(tokenResponse)
  );
  localStorage.setItem(
    config.storageTokenKeyName,
    JSON.stringify(tokenResponse.access_token)
  );
  localStorage.setItem(
    config.storageRefreshTokenKeyName,
    JSON.stringify(tokenResponse.refresh_token)
  );
  localStorage.setItem(
    config.storageTokenExpiresAtKeyName,
    expiresAt.toString()
  );
  localStorage.setItem(config.storageIdTokenKeyName, tokenResponse.id_token);
}

export default async function refreshTokens(): Promise<ITokenResponse | null> {
  const host = `${appConfig.urls.IDENTITY_SERVER}${appConfig.urls.IDENTITY_SERVER_TOKEN_ENDPOINT}`;
  const grantType = 'refresh_token';
  const refreshToken = localStorage.getItem(config.storageRefreshTokenKeyName);

  const params = new URLSearchParams();
  params.append('client_id', config.appClientId);
  params.append('grant_type', grantType);
  params.append('refresh_token', refreshToken?.replace(/['"]+/g, '') ?? '');

  try {
    const response = await axios.post<ITokenResponse>(host, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    setLocalStorageValues(response.data);
    return response.data;
  } catch {
    clearStorage();
    window.location.href = `${window.location.origin}/session-expired`;
  }
  return null;
}
