/* eslint-disable no-await-in-loop */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosClient } from '@src/app/AxiosClient';
import { BLANK_ACCOUNT, IAccount } from '@src/app/types';
import _ from 'lodash';
import { BLANK_BUSINESS, BLANK_LOCATION, ILocation } from '../locations/types';
import { BLANK_AVAILABILITY, IAvailableSlot } from '../pricing/types';
import { IAppointment } from './types';
import { PaymentState } from '../payment/PaymentSlice';
import { IBusiness } from '../business/types';
import { Credit, CreditType } from '../api/OrderApi';
import { IService } from '../api/TreatmentsApi';

export enum BookingStatus {
  Initial = 'Initial',
  Pending = 'Pending',
  Success = 'Success',
  Fail = 'Fail',
  Canceled = 'Canceled',
  TimedOut = 'TimedOut', // webapp specific, when polling times out
}

export interface IBookingStatus {
  status: BookingStatus;
  failureCode: string;
}

export interface IBookingTreatment {
  name: string;
  price: string;
  category: string;
}

export interface IBookingAddOn {
  name: string;
  price: string;
  recipient?: number;
}

export interface IBookingTimeSlot {
  starts: string;
  durationInMinutes: number;
}
export interface IBookingRequest {
  appointmentId: string;
  addOns: IBookingAddOn[];
  treatments: IBookingTreatment[];
  timeSlot: IBookingTimeSlot;
  locationId: string;
  timeZone: string;
  orderId: string;
  businessId: string;
  email: string;
  firstName: string;
  saveCardForFutureBookings: boolean;
  customerId: string;
  credits: Credit[];
}
interface BookingState {
  selectedBusiness: IBusiness;
  selectedLocation: ILocation;
  selectedDate: Date;
  selectedAccount: IAccount;
  selectedAvailability: IAvailableSlot;
  selectedServiceCategory: string | null;
  locationTreatments: IService[];
  validTherapistIds: string[][];
  validTreatmentIds: string[][];
  validAddOnIds: string[][];
  appointmentReservationId: string;
  bookingStatus: BookingStatus;
  bookingFailureCode: string;
}

const initialState: BookingState = {
  selectedBusiness: BLANK_BUSINESS,
  selectedLocation: BLANK_LOCATION,
  selectedDate: new Date(),
  selectedAccount: BLANK_ACCOUNT,
  selectedAvailability: BLANK_AVAILABILITY,
  selectedServiceCategory: null,
  locationTreatments: [],
  validTherapistIds: [],
  validTreatmentIds: [],
  validAddOnIds: [],
  appointmentReservationId: '',
  bookingStatus: BookingStatus.Initial,
  bookingFailureCode: '',
};

type IPaymentData = {
  saveCard: boolean;
  reservedAppointment: IAppointment;
  orderId: string;
};

export const submitBooking = createAsyncThunk<
  IBookingStatus,
  IPaymentData,
  { state: { booking: BookingState; payment: PaymentState } }
>('booking/submitBooking', async (paymentData: IPaymentData, { getState }) => {
  const { booking, payment } = getState();
  const credits: Credit[] = [];
  if (payment.selectedGiftCard) {
    credits.push({
      type: CreditType.GiftCard,
      paymentToken: payment.selectedGiftCard.code,
    });
  }
  const request: IBookingRequest = {
    orderId: paymentData.orderId,
    businessId: paymentData.reservedAppointment.businessId,
    locationId: booking.selectedLocation.id,
    timeZone: booking.selectedLocation.timeZone,
    customerId: booking.selectedAccount.id,
    appointmentId: paymentData.reservedAppointment.id,
    email: booking.selectedAccount.email,
    firstName: booking.selectedAccount.firstName,
    saveCardForFutureBookings: paymentData.saveCard,
    treatments: paymentData.reservedAppointment.treatments.map((t) => ({
      serviceId: t.serviceId,
      name: t.name,
      price: t.price,
      category: t.category,
    })),
    addOns: paymentData.reservedAppointment.addOns.map((a) => ({
      id: a.id,
      name: a.name,
      price: a.price,
      recipient: a.recipient,
    })),
    timeSlot: {
      starts: new Date(
        paymentData.reservedAppointment.timeSlot.starts
      ).toISOString(),
      durationInMinutes:
        paymentData.reservedAppointment.timeSlot.durationInMinutes,
    },
    credits,
  };

  const api = new AxiosClient({});
  const createBookingResponse = await api.postRequest('/booking-v2', request);
  const bookingId = createBookingResponse.headers.location.split('/')[1];

  let attempts = 0;
  let bookingStatusData: IBookingStatus = {
    status: BookingStatus.Initial,
    failureCode: '',
  };
  while (attempts < 30) {
    attempts += 1;
    const bookingStatusResponse = await api.getRequest(`/booking/${bookingId}`);
    bookingStatusData = bookingStatusResponse.data;
    if (
      bookingStatusData.status === BookingStatus.Success ||
      bookingStatusData.status === BookingStatus.Fail
    ) {
      break;
    }
    await new Promise((f) => setTimeout(f, 2000));
  }
  if (bookingStatusData.status === BookingStatus.Pending) {
    // Unknown booking status. Polling TimedOut
    bookingStatusData.status = BookingStatus.TimedOut;
  }
  return bookingStatusData;
});

export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    selectBusiness: (
      state: BookingState,
      action: { payload: IBusiness | undefined; type: string }
    ) => {
      if (!action.payload) {
        state.selectedBusiness = BLANK_BUSINESS;
      } else {
        state.selectedBusiness = action.payload;
      }
    },
    selectLocation: (
      state: BookingState,
      action: { payload: ILocation | undefined; type: string }
    ) => {
      if (!action.payload) {
        state.selectedLocation = BLANK_LOCATION;
      } else {
        state.selectedLocation = action.payload;
      }
    },
    selectDate: (
      state: BookingState,
      action: { payload: Date; type: string }
    ) => {
      state.selectedDate = action.payload;
    },
    selectAvailability: (
      state: BookingState,
      action: { payload: IAvailableSlot; type: string }
    ) => {
      const slot = action.payload;
      const slotWithShuffledTherapists: IAvailableSlot = {
        ...slot,
        therapists: _.shuffle(slot.therapists),
      };
      state.selectedAvailability = slotWithShuffledTherapists;
    },
    selectServiceCategory: (
      state: BookingState,
      action: { payload: string | null; type: string }
    ) => {
      state.selectedServiceCategory = action.payload;
    },
    selectAccount: (
      state: BookingState,
      action: { payload: IAccount; type: string }
    ) => {
      state.selectedAccount = action.payload;
    },
    setLocationTreatments: (
      state: BookingState,
      action: { payload: IService[]; type: string }
    ) => {
      state.locationTreatments = action.payload;
    },
    setValidTherapistIds: (
      state: BookingState,
      action: { payload: string[][]; type: string }
    ) => {
      state.validTherapistIds = action.payload;
    },
    setValidTreatmentIds: (
      state: BookingState,
      action: { payload: string[][]; type: string }
    ) => {
      state.validTreatmentIds = action.payload;
    },
    setValidAddOnIds: (
      state: BookingState,
      action: { payload: string[][]; type: string }
    ) => {
      state.validAddOnIds = action.payload;
    },
    setAppointmentReservationId: (
      state: BookingState,
      action: { payload: string; type: string }
    ) => {
      state.appointmentReservationId = action.payload;
    },
    resetBookingStatus: (state: BookingState) => {
      state.bookingStatus = BookingStatus.Initial;
      state.bookingFailureCode = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitBooking.pending, (state) => {
        state.bookingStatus = BookingStatus.Pending;
      })
      .addCase(submitBooking.fulfilled, (state, action) => {
        state.bookingStatus = action.payload.status;
        state.bookingFailureCode = action.payload.failureCode;
      })
      .addCase(submitBooking.rejected, (state) => {
        state.bookingStatus = BookingStatus.Fail;
      });
  },
});

export const {
  selectBusiness,
  selectLocation,
  selectDate,
  selectAvailability,
  selectServiceCategory,
  selectAccount,
  setLocationTreatments,
  setValidTherapistIds,
  setValidTreatmentIds,
  setValidAddOnIds,
  setAppointmentReservationId,
  resetBookingStatus,
} = bookingSlice.actions;

export default bookingSlice.reducer;
