import { Role } from '@src/app/types'; // TODO - Roles
import jwt from '@src/auth/jwt/useJwt';
import jwt_decode from 'jwt-decode';
import {
  IAbility,
  IDecodedIdToken,
  IUserData,
} from '@src/features/authentication/types';

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  const currentDate = Date.now();
  const expiresAt = parseInt(
    localStorage.getItem(jwt.jwtConfig.storageTokenExpiresAtKeyName) ?? '',
    10
  );
  return (
    localStorage.getItem(jwt.jwtConfig.userDataKeyName) &&
    localStorage.getItem(jwt.jwtConfig.storageTokenKeyName) &&
    expiresAt > currentDate
  );
};

export const getUserData = () => {
  try {
    return JSON.parse(
      localStorage.getItem(jwt.jwtConfig.userDataKeyName) ?? ''
    );
  } catch {
    return null;
  }
};

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRoles: Role[]) => {
  if (userRoles.includes(Role.Customer)) {
    return '/';
  }
  return '/misc/not-authorized';
};

const getRoleBasedAbilities = (roles: Role[]): IAbility[] => {
  const abilities: IAbility[] = [];
  if (roles.includes(Role.Customer)) {
    abilities.push({ action: 'read', subject: 'locations' });
  }
  return abilities;
};

export const decodeUserDataFromIdToken = (token: string): IUserData => {
  const userData: IUserData = {
    accountId: '',
    roles: [],
    locationClaims: [],
    abilities: [],
  };
  const decodedToken = jwt_decode(token) as IDecodedIdToken;

  if (typeof decodedToken.role === 'string') {
    userData.roles = [decodedToken.role as Role];
  } else if (typeof decodedToken.role === 'object') {
    userData.roles = decodedToken.role as Role[];
  }
  if (typeof decodedToken.loc === 'string') {
    userData.locationClaims = [decodedToken.loc as string];
  } else if (typeof decodedToken.loc === 'object') {
    userData.locationClaims = decodedToken.loc as string[];
  }

  userData.abilities = getRoleBasedAbilities(userData.roles);
  userData.accountId = decodedToken.sub;

  return userData;
};

export const decodeExpiresAtFromToken = (token: string): number => {
  const decodedToken = jwt_decode(token) as IDecodedIdToken;
  return decodedToken.exp * 1000;
};
