import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@src/app/store';
import jwt from '@src/auth/jwt/useJwt';
import { decodeUserDataFromIdToken } from '@src/auth/utils';
import appConfig from '@src/configs/appConfig';
import {
  generatePkceChallenge,
  generatePkceVerifier,
} from '@src/utility/Utils';
import IdentityServerApi from './IdentityServerApi';
import refreshTokens, {
  clearStorage,
  setLocalStorageValues,
} from './TokenRefresh';
import { BLANK_USER_DATA, ITokenResponse, IUserData } from './types';

const config = jwt.jwtConfig;

async function buildAuthorizeEndpointAndRedirect() {
  const host = `${appConfig.urls.IDENTITY_SERVER}${appConfig.urls.IDENTITY_SERVER_AUTHORIZE_ENDPOINT}`;
  const redirectUri = `${window.location.origin}/oidc-callback`;
  const scope =
    'weatherapi.read weatherapi.write openid profile email phone offline_access';

  const verifier = generatePkceVerifier();
  const challenge = generatePkceChallenge(verifier);

  // Build endpoint
  const endpoint = `${host}?response_type=code&client_id=${config.appClientId}&scope=${scope}&redirect_uri=${redirectUri}&code_challenge=${challenge}&code_challenge_method=S256&prompt=login`;

  // Set verifier to local storage
  localStorage.setItem('verifier', verifier);

  return endpoint;
}

async function buildLogoutEndpointAndRedirect() {
  const host = `${appConfig.urls.IDENTITY_SERVER}${appConfig.urls.IDENTITY_SERVER_LOGOUT_ENDPOINT}`;
  const idToken = localStorage.getItem(config.storageIdTokenKeyName);

  const endpoint = `${host}?id_token_hint=${idToken}`;

  return endpoint;
}

async function getToken(verifier: string | null, code: string | void) {
  const redirectUri = `${window.location.origin}/oidc-callback`;
  const grantType = 'authorization_code';

  // Build params to send to token endpoint
  const params = new URLSearchParams();
  params.append('client_id', config.appClientId);
  params.append('grant_type', grantType);
  params.append('code_verifier', verifier ?? '');
  params.append('redirect_uri', redirectUri);
  params.append('code', code ?? '');

  // Make a POST request
  const api = new IdentityServerApi({
    baseUrl: appConfig.urls.IDENTITY_SERVER,
  });
  const response = await api.getToken(params);
  return response;
}

export const loginUser = createAsyncThunk(
  'authentication/loginUser',
  async () => {
    const endpoint = await buildAuthorizeEndpointAndRedirect();
    window.location.href = endpoint;
  }
);

export const logoutUser = createAsyncThunk(
  'authentication/logoutUser',
  async () => {
    const endpoint = await buildLogoutEndpointAndRedirect();
    clearStorage();
    window.location.href = endpoint;
  }
);

export const fetchToken = createAsyncThunk<ITokenResponse, string>(
  'authentication/fetchToken',
  async (code) => {
    const verifier = localStorage.getItem('verifier');
    const response = await getToken(verifier, code);
    return response;
  }
);

export const tryRefresh = createAsyncThunk<ITokenResponse | null, void>(
  'authentication/tryRefresh',
  async () => {
    const currentDate = Date.now();
    const expiresAt = parseInt(
      localStorage.getItem(jwt.jwtConfig.storageTokenExpiresAtKeyName) ?? '',
      10
    );
    let tokenResponse: ITokenResponse | null;
    if (expiresAt && expiresAt < currentDate + 30_000) {
      tokenResponse = await refreshTokens();
      return tokenResponse;
    }
    const tokenResponseJson = localStorage.getItem(
      jwt.jwtConfig.storageTokenResponseKeyName
    );
    const tokenData: ITokenResponse = JSON.parse(tokenResponseJson!);
    return tokenData;
  }
);

interface UserState {
  userData: IUserData;
  tokenData: ITokenResponse | null;
  forgotPasswordEmail: string | null;
}

const initialState: UserState = {
  userData: BLANK_USER_DATA,
  tokenData: null,
  forgotPasswordEmail: null,
};

export const AuthenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    selectForgotPasswordEmail: (
      state: UserState,
      action: { payload: string | null }
    ) => {
      state.forgotPasswordEmail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchToken.fulfilled, (state, action) => {
        setLocalStorageValues(action.payload);
        state.tokenData = action.payload;
        state.userData = decodeUserDataFromIdToken(action.payload.id_token);
        localStorage.removeItem('verifier');
      })
      .addCase(fetchToken.rejected, (_, action) => {
        // eslint-disable-next-line no-console
        console.log('Rejected Payload: ', action.payload);
      })
      .addCase(tryRefresh.fulfilled, (state, action) => {
        if (action.payload) {
          setLocalStorageValues(action.payload);
          state.tokenData = action.payload;
          state.userData = decodeUserDataFromIdToken(action.payload.id_token);
        }
      })
      .addCase(tryRefresh.rejected, () => {
        clearStorage();
        window.location.href = `${window.location.origin}/session-expired`;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.userData = BLANK_USER_DATA;
        state.tokenData = null;
      });
  },
});

export const selectUserData = (state: RootState) => state.auth.userData;
export const { selectForgotPasswordEmail } = AuthenticationSlice.actions;

export default AuthenticationSlice.reducer;
