import logo from '@src/assets/images/logo/logo-icon-light.svg';

const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner app-loader">
      <img
        className="fallback-logo"
        src={logo}
        alt="logo"
        style={{ maxWidth: '60px' }}
      />
      <div className="loading">
        <div className="effect-1 effects" />
        <div className="effect-2 effects" />
        <div className="effect-3 effects" />
      </div>
    </div>
  );
};

export default SpinnerComponent;
